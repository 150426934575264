import React from "react";
import utilStyles from "@/styles/utils.module.scss";
import BgImage from "@/components/BgImage";

export default function Loading() {
  return (
    <div style={{ width: "100%", zIndex: 1000 }}>
      <BgImage />
      <span className={utilStyles.loading}>Loading...</span>
    </div>
  );
}

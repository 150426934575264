import React, { useEffect, useState } from "react";
import accountStyles from "@/styles/account.module.scss";
import utilStyles from "@/styles/utils.module.scss";
import UserHeader from "@/components/UserHeader";
import { ShortUserView } from "@/api/generated";

export default function DescribeExample() {
  const exampleUser = {
    username: "yourname",
    full_name: "Your Name",
    avatar: "/images/no-image.png",
  } as ShortUserView;
  const exampleText =
    "He/She is a compassionate, ever-growing individual, known for sincerity and honesty. Deeply committed to environmental causes, he/she regularly volunteers in community projects. Surprisingly, despite an organized lifestyle, he/she finds tidying his/her room challenging.";
  const [displayedText, setDisplayedText] = useState("");

  useEffect(() => {
    const timer = setInterval(() => {
      setDisplayedText((prevText) => {
        const nextChar = exampleText[prevText.length];
        return nextChar ? prevText + nextChar : prevText;
      });
    }, 40);

    return () => clearInterval(timer);
  }, []);

  return (
    <form className={[utilStyles.exampleField, accountStyles.write].join(" ")}>
      <div className={accountStyles.writeHeader}>
        <UserHeader user={exampleUser} disableLink={true}></UserHeader>
      </div>

      <div className={accountStyles.writeInput}>
        <textarea
          value={displayedText}
          className={[
            accountStyles.dscribeTextarea,
            accountStyles.hasExample,
          ].join(" ")}
          readOnly
        />
      </div>
    </form>
  );
}

const CODE_CHALLENGE = "NtTFwRLmTbLH2Zb9kJaO5tcOzefDOUVZK3mXaPt1gQs";
export const authOptions = {
  twitter: {
    authUrl:
      `https://twitter.com/i/oauth2/authorize` +
      `?response_type=code` +
      `&client_id=${process.env.NEXT_PUBLIC_TWITTER_CLIENT_KEY}` +
      `&redirect_uri=${process.env.NEXT_PUBLIC_TWITTER_CALL_BACK}` +
      `&scope=tweet.read%20users.read%20follows.read%20offline.access` +
      `&code_challenge=challenge` +
      `&code_challenge_method=plain`,
  },
  instagram: {
    authUrl:
      `https://api.instagram.com/oauth/authorize` +
      `?client_id=${process.env.NEXT_PUBLIC_INSTAGRAM_APP_ID}` +
      `&redirect_uri=${process.env.NEXT_PUBLIC_INSTAGRAM_CALL_BACK}` +
      `&scope=user_profile,user_media&response_type=code`,
  },
  facebook: {
    authUrl:
      `https://www.facebook.com/v15.0/dialog/oauth` +
      `?client_id=${process.env.NEXT_PUBLIC_FACEBOOK_APP_ID}` +
      `&redirect_uri=${process.env.NEXT_PUBLIC_FACEBOOK_CALL_BACK}` +
      `&scope=email`,
  },
  google: {
    authUrl:
      `https://accounts.google.com/o/oauth2/v2/auth` +
      `?client_id=${process.env.NEXT_PUBLIC_GOOGLE_APP_ID}` +
      `&redirect_uri=${process.env.NEXT_PUBLIC_GOOGLE_CALL_BACK}` +
      `&scope=https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email` +
      `&response_type=code`,
  },
  snapchat: {
    authUrl:
      `https://accounts.snapchat.com/accounts/oauth2/auth` +
      `?client_id=${process.env.NEXT_PUBLIC_SNAPCHAT_APP_ID}` +
      `&redirect_uri=${process.env.NEXT_PUBLIC_SNAPCHAT_CALL_BACK}` +
      `&scope=https://auth.snapchat.com/oauth2/api/user.display_name https://auth.snapchat.com/oauth2/api/user.external_id` +
      `&code_challenge=${CODE_CHALLENGE}` +
      `&code_challenge_method=S256` +
      `&response_type=code`,
  },
};

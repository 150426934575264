import React from "react";
import Link from "next/link";
import utilStyles from "@/styles/utils.module.scss";
import { authOptions } from "@/lib/authOptions";
import useStorage from "@/hooks/useStorage";
import { KEY_LAST_VISIT_USER } from "@/lib/storageKeys";
import signUpStyles from "@/styles/signup.module.scss";

export default function SignupButtons({
  invitationCode,
}: {
  invitationCode?: string;
}) {
  const { getSessionItem } = useStorage();

  const state = invitationCode
    ? `&state=invite_${invitationCode}`
    : getSessionItem(KEY_LAST_VISIT_USER)
    ? `&state=user_${getSessionItem(KEY_LAST_VISIT_USER)}`
    : "&state=state";

  return (
    <div className={signUpStyles.buttons}>
      <Link href={authOptions.facebook.authUrl + state}>
        <button
          className={[utilStyles.button, utilStyles.buttonFacebook].join(" ")}
        >
          <span>Continue with Facebook</span>
        </button>
      </Link>

      <Link href={authOptions.google.authUrl + state}>
        <button
          className={[utilStyles.button, utilStyles.buttonGoogle].join(" ")}
        >
          <span>Continue with Google</span>
        </button>
      </Link>

      <Link href={authOptions.snapchat.authUrl + state}>
        <button
          className={[utilStyles.button, utilStyles.buttonSnapchat].join(" ")}
        >
          <span>Continue with Snapchat</span>
        </button>
      </Link>
    </div>
  );
}

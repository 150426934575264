import React from "react";
import Avatar from "./Avatar";
import utilStyles from "@/styles/utils.module.scss";
import { ShortUserView, UserMeView } from "@/api/generated";
import { useRouter } from "next/router";

export default function UserHeader({
  user,
  children,
  disableLink = false,
}: {
  user?: UserMeView | ShortUserView;
  children?: React.ReactNode | null;
  disableLink?: boolean;
}) {
  const router = useRouter();
  if (!user) return null;

  const handleClick = (e: any) => {
    e.stopPropagation();
    if (disableLink) return;
    router.push(("/" + user.username) as string);
  };

  return (
    <div className={utilStyles.user}>
      <div className={utilStyles.userLeft}>
        <div className={utilStyles.userAvatar} onClick={handleClick}>
          <Avatar avatar={user.avatar} width={40} height={40} />
        </div>
        <div className={utilStyles.userDescription}>
          <span className={utilStyles.userDisplayName} onClick={handleClick}>
            {user.full_name}
          </span>
          <span className={utilStyles.userName}>@{user.username}</span>
        </div>
      </div>
      {children}
    </div>
  );
}

import React, { useEffect, useState } from "react";
import Link from "next/link";
import utilStyles from "@/styles/utils.module.scss";
import signUpStyles from "@/styles/signup.module.scss";
import SignupButtons from "@/components/SignupButtons";
import DescribeExample from "@/components/DescribeExample";

export default function SignupSection({
  title,
  invitationCode,
  errorCode,
  hasExample = false,
  isSignUp = false,
}: {
  title: string;
  invitationCode?: string;
  errorCode?: string;
  hasExample?: boolean;
  isSignUp?: boolean;
}) {
  const [errorMessage, setErrorMessage] = useState("");
  useEffect(() => {
    if (!errorCode) return;
    if (errorCode === "access_denied") {
      setErrorMessage("You canceled social login.");
    }
    if (errorCode === "login_failed") {
      setErrorMessage(
        "Sorry, your account was unregistered. Please check your account again."
      );
    }
  }, [errorCode]);

  return (
    <div
      className={[
        signUpStyles.signup,
        signUpStyles.section,
        hasExample ? signUpStyles.hasExample : "",
      ].join(" ")}
    >
      {hasExample && <DescribeExample />}

      <div className={signUpStyles.title}>{title}</div>
      {errorMessage && <p className={signUpStyles.error}>{errorMessage}</p>}

      <div>
        <SignupButtons invitationCode={invitationCode} />
        <div className={signUpStyles.terms}>
          <span>By continuing, I agree to the</span>
          <span>Dscribe.me</span>
          <span>
            <Link href="/terms" className={utilStyles.grayLink}>
              Terms of Service
            </Link>{" "}
            and{" "}
            <Link href="/privacy" className={utilStyles.grayLink}>
              Privacy Policy
            </Link>
            ,
          </span>
          <span>
            including{" "}
            <Link href="/cookies" className={utilStyles.grayLink}>
              Cookies Policy
            </Link>
            .
          </span>
          {isSignUp && (
            <div className={signUpStyles.signupLink}>
              <Link href="/login">Already have an account?</Link>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

import React from "react";
import Image from "next/image";
import utilStyles from "@/styles/utils.module.scss";

export default function Avatar({
  avatar = "/images/no-image.svg",
  width = 36,
  height = 36,
  className = "",
}: {
  avatar?: string;
  width?: number;
  height?: number;
  className?: string;
}) {
  return (
    <div
      className={[utilStyles.avatar, className].join(" ")}
      style={{ width: width, height: height }}
    >
      <Image
        src={avatar}
        width={width}
        height={height}
        objectFit="cover"
        alt="avatar"
      />
    </div>
  );
}

import React from "react";
import Head from "next/head";
import signUpStyles from "@/styles/signup.module.scss";
import AppLayout from "@/components/Layout/AppLayout";
import { useAuth } from "@/hooks/useAuth";
import Loading from "@/components/Loading";
import { useRouter } from "next/router";
import SignupSection from "@/components/SignupSection";

export default function Login() {
  const { error, isLoading } = useAuth({
    middleware: "guest",
    redirectIfAuthenticated: "/home",
  });
  const router = useRouter();
  const { error: errorCode } = router.query;

  if (!error && isLoading) return <Loading />;

  return (
    <AppLayout>
      <Head>
        <title>Log in - Dscribe.me</title>
      </Head>

      <div className={signUpStyles.container}>
        <div className={signUpStyles.description}>
          <span>
            Let your friends dscribe you and tell the world who you are.
          </span>
        </div>

        <SignupSection title="Log in" errorCode={errorCode as string} />
      </div>
    </AppLayout>
  );
}
